import React, { useState, useEffect  } from "react";
import { Calendar } from 'primereact/calendar';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import crossIcon from "../../assets/images/cross.svg";

function Datepicker({ onCheckinDateChange, onCheckoutDateChange, onCheckinDateClear, onCheckoutDateClear, paramCheckinDate, paramCheckoutDate}) {
  
  const [checkinDate, setCheckinDate] = useState(paramCheckinDate ? new Date(paramCheckinDate) : null);
  const [checkoutDate, setCheckoutDate] = useState(paramCheckoutDate ? new Date(paramCheckoutDate) : null);

  useEffect(() => {
    setCheckinDate(paramCheckinDate ? new Date(paramCheckinDate) : null);
  }, [paramCheckinDate]);

  useEffect(() => {
    setCheckoutDate(paramCheckoutDate ? new Date(paramCheckoutDate) : null);
  }, [paramCheckoutDate]);

  const handleStartDateChange = (e) => {
    setCheckinDate(e.value);
    if (onCheckinDateChange) {
      onCheckinDateChange(e.value);
    }
  };

  const handleCheckoutDateChange = (e) => {
    setCheckoutDate(e.value);
    if (onCheckoutDateChange) {
      onCheckoutDateChange(e.value);
    }
  };

  const clearCheckinDate = () => {
    setCheckinDate(null);
    onCheckinDateClear(null);
  };

  const clearCheckoutDate = () => {
    setCheckoutDate(null);
    onCheckoutDateClear(null);
  };

  return (
    <div className="card- flex justify-content-center datepickerblock">

      <div className="d-flex gap-2">

        <div className="p-col">
          <label className="form-label" htmlFor="checkinDate">Check In</label>
          <div className="calendardate">
            <Calendar
              id="checkinDate"
              value={checkinDate}
              onChange={handleStartDateChange}
              showIcon={!checkinDate}
              // touchUI
              readOnlyInput
              placeholder="Tap to enter"
            />
            {checkinDate && (
              <span className="clearbtn" onClick={clearCheckinDate}> <img src={crossIcon} alt="Checked in" /></span>
            )}
          </div>
        </div>
        <div className="p-col">
          <label className="form-label" htmlFor="checkoutDate">Check Out</label>
          <div className="calendardate">
            <Calendar
              id="checkoutDate"
              value={checkoutDate}
              onChange={handleCheckoutDateChange}
              showIcon={!checkoutDate}
              // touchUI
              readOnlyInput
              placeholder="Tap to enter"
              viewDate={checkinDate || new Date()}
            />
            {checkoutDate && (
              <span className="clearbtn" onClick={clearCheckoutDate}> <img src={crossIcon} alt="Checked out" /></span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datepicker;
