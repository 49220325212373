import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from "react";

import WelcomePage from './welcome';
import CheckedInPage from './checkedin';
import CheckingPage from './checkingin';
import ReservationPage from './reservationinfo';

const Common = () => {
  const [isWelcomePage, setWelcomePage] = useState(true);
  const [isCheckedInPage, setCheckedInPage] = useState(false);
  const [isCheckingPage, setCheckingPage] = useState(false);
  const [isReservationPage, setReservationPage] = useState(false);

  const handleCheckedInPage = (data) => {
    if (data) {
      setWelcomePage(false);
      setCheckedInPage(data);
    }
  };

  const handleCheckingPage = (data) => {
    if (data) {
      setWelcomePage(false);
      setCheckedInPage(false);
      setCheckingPage(data);
    }
  };

  const handleReservationPage = (data) => {
    if (data) {
      setWelcomePage(false);
      setCheckedInPage(false);
      setCheckingPage(false);
      setReservationPage(data);
    }
  };

  useEffect(() => {

  }, [handleCheckedInPage, handleCheckingPage, handleReservationPage]);

  return (
    <>
      {isWelcomePage && <WelcomePage checkedInPageHandle={handleCheckedInPage} checkingPageHandle={handleCheckingPage} reservationPageHandle={handleReservationPage} />}
      {isCheckedInPage && <CheckedInPage checkedInPageHandle={handleCheckedInPage} checkingPageHandle={handleCheckingPage} reservationPageHandle={handleReservationPage} />}
      {isCheckingPage && <CheckingPage checkedInPageHandle={handleCheckedInPage} checkingPageHandle={handleCheckingPage} reservationPageHandle={handleReservationPage} />}
      {isReservationPage && <ReservationPage />}
    </>
  );
};

export default Common;
