import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { history, fetchWrapper } from "../helpers/index";

// Create slice
const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// Exports
export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// Implementation
function createInitialState() {
  return {
    // Initialize state from local storage to enable user to stay logged in
    value: JSON.parse(localStorage.getItem("auth")),
  };
}

function createReducers() {
  return {
    setAuth,
  };

  function setAuth(state, action) {
    state.value = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

  return {
    login: login(),
    logout: logout(),
  };

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async function ({ username, password }, { dispatch }) {
        try {
          const user = await fetchWrapper.post(`${baseUrl}/authenticate`, {
            username,
            password,
          });

          // Set auth user in redux state
          dispatch(authActions.setAuth(user));

          // Store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("auth", JSON.stringify(user));

          // Get return url from location state or default to home page
          const { from } = history.location.state || {
            from: { pathname: "/" },
          };
          history.navigate(from);
        } catch (error) {

        }
      }
    );
  }

  function logout() {
    return createAsyncThunk(`${name}/logout`, function (arg, { dispatch }) {
      dispatch(authActions.setAuth(null));
      localStorage.removeItem("auth");
      history.navigate("/account/login");
    });
  }
}
