import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import HttpStatus from "../helpers/status.enum";

/* Create slice */
const name = "onboarding";
const reducers = createReducers();
const initialState = createInitialState();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

/* Exports */
export const onboardingActions = { ...slice.actions, ...extraActions };
export const onboardingReducer = slice.reducer;

/* Implementation */
function createInitialState() {
  return {
    value: null,
    onboardingStep: null
  };
}


function createReducers() {
  return {
    setOnboardingStep
  };

  function setOnboardingStep(state, action) {
    state.value = action.payload;
    state.onboardingStep = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  return {
    submitOnboardingSteps: submitOnboardingSteps(),
    getOnboardingStep: getOnboardingStep(),
    uploadMedia: uploadMedia(),
    getListingCmsInfo: getListingCmsInfo(),
    markAsCheckedOut: markAsCheckedOut(),
  };

  /* Update Onboarding steps */
  function submitOnboardingSteps() {
    return createAsyncThunk(
      `${name}/submitOnboardingSteps`,
      async (request) => await fetchWrapper.post(`${baseUrl}/checkin/onboarding`, request)
    );
  }

  /* Get completed Onboarding steps */
  function getOnboardingStep() {
    return createAsyncThunk(
      `${name}/getOnboardingStep`,
      async (id, { dispatch }) => {
        const result = await fetchWrapper.get(`${baseUrl}/checkin/get-onboarding-step/${id}`);
        /* Set onboarding steps in redux */
        if (result.status === HttpStatus.HTTP_OK) {
          const response = result.result;
          dispatch(onboardingActions.setOnboardingStep(response));
          return response;
        }
        return null;
      }
    );
  }

  /* Media files for Onboarding steps */
  function uploadMedia() {
    return createAsyncThunk(
      `${name}/uploadMedia`,
      async (request) => await fetchWrapper.post(`${baseUrl}/upload-guest-ids`, request)
    );
  }

  /* To get booking detail by passing the request */
  function getListingCmsInfo() {
    return createAsyncThunk(
      `${name}/getListingCmsInfo`,
      async (id, { dispatch }) => {
        const result = await fetchWrapper.get(`${baseUrl}/listing-cms-info/${id}`);
        return result;
      }
    );
  }

  /* Update Checked out */
  function markAsCheckedOut() {
    return createAsyncThunk(
      `${name}/markAsCheckedOut`,
      async (request) => await fetchWrapper.post(`${baseUrl}/checked-out`, request)
    );
  }

}
