import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import HttpStatus from "../helpers/status.enum";

/* Create slice */
const name = "payment";
const initialState = createInitialState();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState });

/* Exports */
export const paymentActions = { ...slice.actions, ...extraActions };
export const paymentReducer = slice.reducer;

/* Implementation */
function createInitialState() {
  return {
    value: null,
  };
}



function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  return {
    createPaymentIntent: createPaymentIntent(),
    updatePaymentStatus: updatePaymentStatus()
  };

  /* Create a payment intent */
  function createPaymentIntent() {
    let response;
    return createAsyncThunk(
      `${name}/createPaymentIntent`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(`${baseUrl}/create-payment-intent`, request);
        return result;
      }
    );
  }

  /* Update Payment status */
  function updatePaymentStatus() {
    let response;
    return createAsyncThunk(
      `${name}/updatePaymentStatus`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(`${baseUrl}/update-payment-status`, request);
        if (result.status === HttpStatus.HTTP_OK) {
          response = result;
        }
        return response;
      }
    );
  }

}
