import React from 'react';

function Privacypolicy({ data }) {
   return (
      <section>
         <span dangerouslySetInnerHTML={{ __html: data }} />
      </section>

   );
}
export default Privacypolicy;
