import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import HttpStatus from "../helpers/status.enum";

/* Create slice */
const name = "common";
const initialState = createInitialState();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState });

/* Exports */
export const commonActions = { ...slice.actions, ...extraActions };
export const commonReducer = slice.reducer;

/* Implementation */
function createInitialState() {
  return {
    value: null,
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  return {
    getCmsContents: getCmsContents(),
    postInquiry: postInquiry(),
    todayScheduledCleaning: todayScheduledCleaning(),
    markAsCleaned: markAsCleaned(),
  };

  /* Get CMS contents intent */
  function getCmsContents() {
    let response;
    return createAsyncThunk(
      `${name}/getCmsContents`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.get(
          `${baseUrl}/get-cms-content`,
          request
        );
        if (result.status === HttpStatus.HTTP_OK) {
          response = result;
        }
        // console.log("CMS content  ", response)
        return response;
      }
    );
  }

  /* To submit inquiry form */
  function postInquiry() {
    return createAsyncThunk(
      `${name}/postInquiry`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/post-inquiry`,
          request
        );
        return result;
      }
    );
  }

  /* Get Today Scheduled Cleaning */
  function todayScheduledCleaning() {
    return createAsyncThunk(
      `${name}/todayScheduledCleaning`,
      async (listingId, { dispatch }) => {
        const result = await fetchWrapper.get(
          `${baseUrl}/today-scheduled-cleaning/${listingId}`
        );
        return result;
      }
    );
  }

  /* To submit inquiry form */
  function markAsCleaned() {
    return createAsyncThunk(
      `${name}/markAsCleaned`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/mark-as-cleaned`,
          request
        );
        return result;
      }
    );
  }
}
