import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authReducer } from "./auth.slice";
import { checkInReducer } from "./checkin.slice";
import { onboardingReducer } from "./onboarding.slice";


export * from "./auth.slice";
export * from "./users.slice";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "error"], // properties you don't want to persist
};

const checkInPersistConfig = {
  key: "checkIn",
  storage: storage,
  blacklist: [], // specify properties to ignore if needed
};

const onboardingPersistConfig = {
  key: "onboarding",
  storage: storage,
  blacklist: [], // specify properties to ignore if needed
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedCheckInReducer = persistReducer(checkInPersistConfig, checkInReducer);
const persistedOnboardingReducer = persistReducer(onboardingPersistConfig, onboardingReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    checkIn: persistedCheckInReducer,
    onboarding: persistedOnboardingReducer,
  },
});

export const persistor = persistStore(store);
