import React from 'react';
function Termsofserevice({ data }) {
   return (
      <section>
         <span dangerouslySetInnerHTML={{ __html: data }} />
      </section>

   );
}
export default Termsofserevice;
