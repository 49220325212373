import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import HttpStatus from "../helpers/status.enum";

/* Create slice */
const name = "earlyCheckin";
const initialState = createInitialState();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState });

/* Exports */
export const earlyCheckin = { ...slice.actions, ...extraActions };
export const earlyCheckinReducer = slice.reducer;

/* Implementation */
function createInitialState() {
  return {
    value: null,
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  return {
    getAdvertisement: getAdvertisement(),
    createPaymentIntent: createPaymentIntent(),
    updatedCheckinCheckoutTime: updatedCheckinCheckoutTime(),
    // updatePaymentStatus: updatePaymentStatus(),
  };

  /* Create a payment intent */
  function createPaymentIntent() {
    return createAsyncThunk(
      `${name}/createPaymentIntent`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/create-advertisement-payment-intent`,
          request
        );
        return result;
      }
    );
  }

  /* To get advertisement  */
  function getAdvertisement() {
    return createAsyncThunk(
      `${name}/getAdvertisement`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/get-advertisement`,
          request
        );
        return result;
      }
    );
  }

  /* Update chechin-checkout time  */
  function updatedCheckinCheckoutTime() {
    return createAsyncThunk(
      `${name}/updatedCheckinCheckoutTime`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/updated-checkin-checkout-time`,
          request
        );
        return result;
      }
    );
  }

  /* Update Payment status */
  function updatePaymentStatus() {
    let response;
    return createAsyncThunk(
      `${name}/updatePaymentStatus`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/update-checkin-checkout-payment-status`,
          request
        );
        if (result.status === HttpStatus.HTTP_OK) {
          response = result;
        }
        return response;
      }
    );
  }
}
