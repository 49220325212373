import { Form, Modal, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { commonActions } from "../store/common.slice";
import HttpStatus from "./../helpers/status.enum";
import SpinnerLoader from "../components/SpinnerLoader";

function Home() {
  const dispatch = useDispatch();
  const [showRequestinvitationModal, setShowRequestinvitationModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [buttonText, setButtonText] = useState("Contact Happy Guest");
  const [hostButtonText, setHostButtonText] = useState("Visit The Marketing Site");

  const handleRequestinvitation = () => {
    setShowRequestinvitationModal(true);
  }

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [signUp, setSignUp] = useState(true);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add("invitationpagebg");
    return () => {
      document.body.classList.remove("invitationpagebg");
    };
  }, []);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // Form is valid, so change button text and simulate form submission
      event.preventDefault();
      setIsLoading(true);
      const formData = {
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "message": message,
        "signup_for_future_update": signUp
      }
      const result = await dispatch(
        commonActions.postInquiry(formData)
      );
      const data = result?.payload || null;
      if (data?.status === HttpStatus.HTTP_OK) {
        console.log("Inquery submitted!")
      } else {
        console.error("Inquery submission failed!")
      }

      setFormSubmitted(true);
      setButtonText("Submitting");
      // setTimeout(() => {
        setButtonText("Contact Happy Guest");
        setHostButtonText("Visit The Marketing Site");
        
        setIsLoading(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        setSignUp(false);

        setFormSubmitted(false);
        setShowThankYouMessage(true);
        // Additional logic for handling actual form submission can be added here
      // }, 500); // Change back the button text after 2 seconds (simulating form submission delay)
    }
    setValidated(true);
  };

  const handleCloseModal = () => {
    setShowRequestinvitationModal(false);
    // Reset form state variables
    setValidated(false);
    setFormSubmitted(false);
    setShowThankYouMessage(false);
  };

  return (
    <section className="mainpage">
      <div className="content">
        <figure>
          <img src={logo} alt="Happy Guest Logo" />
        </figure>
        <div className="w-100 p-2 text-center">
          <h1><strong>For GUESTS:</strong></h1>
          {/* <Button className="fillbutton" onClick={handleRequestinvitation}>
            {buttonText}
          </Button> */}
          <Link to="https://www.happyguestofficial.com/guestcontact" className="request_invite_btn" target="_blank">
            <Button className="fillbutton">
              {buttonText}
            </Button>
          </Link>
        </div>
        <div className="w-100 p-2 text-center for-host-sec">
          <h1><strong>For Hosts:</strong></h1>
          {/* <Button className="fillbutton" onClick={handleRequestinvitation}>
            {buttonText}
          </Button> */}
          <Link to="https://www.happyguestofficial.com" className="request_invite_btn" target="_blank">
            <Button className="fillbutton">
              {hostButtonText}
            </Button>
          </Link>
        </div>
      </div>

      {/* request invitation */}
      <Modal
        show={showRequestinvitationModal}
        onHide={handleCloseModal}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="requestinvitation"
      >
        <Modal.Header
          closeButton
          onClick={handleCloseModal}
        ></Modal.Header>
        <Modal.Body>
          {showThankYouMessage ? (
            <div className="thankmsg">
              <h2 className="text-center">Request Invitation</h2>
              <div className="text-center">
             
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                  <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                  <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                </svg>

                 <p>Thank You!</p>
              </div>
            </div>
          ) : (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <h2>Request Invitation</h2>
              <Form.Group className="mb-0" >
                <Form.Label>Name <b>(Required)</b></Form.Label>
                <Row>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                    <Form.Label className="sublabel">First name</Form.Label>
                    <Form.Control
                      type="text"
                      className="input-text2"
                      required
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a first name.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                    <Form.Label className="sublabel">Last name</Form.Label>
                    <Form.Control
                      type="text"
                      className="input-text2"
                      required
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a last name.
                    </Form.Control.Feedback>
                  </div>
                </Row>
              </Form.Group>
              <Form.Group className="mb-0">
                <Form.Label>Email <b>(Required)</b></Form.Label>
                <Form.Control
                  type="email"
                  className="input-text2"
                  required
                  pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Sign up for news and updates"
                  name="newsupdate"
                  className="sublabel"
                  // required
                  id="signUp"
                  checked={signUp}
                  onChange={(e) => setSignUp(e.target.checked)}
                />
                <Form.Control.Feedback type="invalid">
                  You must agree to sign up for news and updates.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message <b>(Required)</b></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="input-text2"
                  required
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a message.
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                className="fillbutton"
                type="submit"
              >
                {/* {buttonText} */}
                {isLoading ? (<><SpinnerLoader /></>) : buttonText}
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
      {/* End request invitation */}
    </section>
  );
}

export default Home;
