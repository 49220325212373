import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

const SpinnerLoader = () => {
    return (
        <>
            <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
                className="spinner_loader"
                variant="light"
                size="sm"
            />
            <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
                className="spinner_loader"
                variant="light"
                size="sm"
            />
            <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
                className="spinner_loader"
                variant="light"
                size="sm"
            />
        </>
    );
};

export default SpinnerLoader;
