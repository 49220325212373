/**
 * HttpStatus
 * 
 * Description:
 * To defined the standard HTTP status code
 * 
 */
const HttpStatus = Object.freeze({
  HTTP_OK: 200,
  HTTP_BAD_REQUEST: 400,
  HTTP_UNPROCESSABLE_ENTITY: 422,
  HTTP_UNAUTHORIZED: 401,
  HTTP_FORBIDDEN: 403,
  HTTP_NOT_FOUND: 404,
  HTTP_METHOD_NOT_ALLOWED: 405,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_CONFLICT: 409,
});

export default HttpStatus;
